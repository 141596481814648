import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useUser } from "@/client/services/user";

export const AuthGuard = () => {
  const location = useLocation();
  const { user, loading } = useUser();

  if (loading) return null;

  console.log(user);

  // eslint-disable-next-line eqeqeq
  if (user == undefined) {
    window.location.href = "https://galio.myiuc.com/";
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (user) {
    return <Outlet />;
  }

  return <Navigate replace to={`/dashboard/resume`} />;
};
